import { Environment } from "./configProvider";
import dataProvider from './dataProvider';
import refreshAuth from "./refreshAuth";
import {addRefreshAuthToAuthProvider, localStorageStore} from 'react-admin';

const cleanup = () => {
    // Remove the ?code&state from the URL
    window.history.replaceState(
        {},
        window.document.title,
        window.location.origin
    );
}

const myAuthProvider = {
    // called when the user attempts to log in
    login: ({ username, password, authCode, source }) => {
        var request;
        if (authCode !== undefined && authCode !== null) {
            request = new Request(`${Environment.API.Auth}/auth/${source}`, {
                method: 'POST',
                body: JSON.stringify({ authorisationCode: authCode, redirectUri: Environment.Auth.RedirectUri, email: username, hostName: Environment.HostName }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });
        } else {
            request = new Request(`${Environment.API.Auth}/auth`, {
                method: 'POST',
                body: JSON.stringify({ clientId: username, clientSecret: password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });
        }
        return fetch(request)
            .then(response => response.json())
            .then(async auth => {
                if (auth.accessToken) { 
                    // Set the auth token and expiry
                    localStorage.setItem('auth', auth.accessToken);
                    localStorage.setItem('auth_expiry', auth.accessTokenExpiryUtc);

                    // Set the refresh token and expiry
                    localStorage.setItem('refresh', auth.refreshToken);
                    localStorage.setItem('refresh_expiry', auth.refreshTokenExpiryUtc);

                    // Set the client Id (needed for refreshing token)
                    localStorage.setItem('clientId', username);
                    
                    // Get the identity values
                    await dataProvider
                        .getOne('identity')
                        .then(response => localStorage.setItem('identity', JSON.stringify(response.data)));

                    // Remove the ?code&state from the URL
                    cleanup();
                }
                else
                    throw new Error(auth.message);
            })
            .then(() => {
                // Re-Set preferences that may have been set from previous session
                copyStorePreferences('CustomerPortal.preferences', 'RaStore.preferences');
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        // Copy preferences on logout so that they are not lost
        copyStorePreferences('RaStore.preferences', 'CustomerPortal.preferences');
        
        localStorage.removeItem('auth');
        localStorage.removeItem('auth_expiry');
        localStorage.removeItem('refresh');
        localStorage.removeItem('refresh_expiry');
        localStorage.removeItem('identity');
        //localStorage.removeItem("remember_username");
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401) {
            localStorage.removeItem('auth');
            localStorage.removeItem('auth_expiry');
            localStorage.removeItem('refresh');
            localStorage.removeItem('refresh_expiry');
            localStorage.removeItem('identity');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        // Check if auth token exists
        if (!localStorage.getItem('auth') || !localStorage.getItem('auth_expiry'))
            return Promise.reject({ message: false });

        // Check if auth expired
        let authExpiry = Date.parse(localStorage.getItem('auth_expiry'));
        let now = new Date();
        if (authExpiry < now)
            return Promise.reject();

        // Success
        return Promise.resolve();
    },
    // get the user's profile
    getIdentity: () => {
        const identity = JSON.parse(localStorage.getItem('identity'));
        if (identity) {
            return Promise.resolve({
                id: identity.id,
                fullName: identity.username
            });
        }
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

// React admin clears local storage on logout, this copies the column preferences so that they can be set on login again
function copyStorePreferences(originalPrefix, copyPrefix) {
    // Loop through all items in localStorage
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (key.startsWith(originalPrefix)) {
            const value = localStorage.getItem(key);
            const newKey = key.replace(originalPrefix, copyPrefix);

            // Save the value under the new key
            localStorage.setItem(newKey, value);
        }
    }
}

const authProvider = addRefreshAuthToAuthProvider(myAuthProvider, refreshAuth);
export default authProvider;